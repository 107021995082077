import React, { useState, useEffect } from 'react';
//import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { reqGET, /*set_language,*/ repo_logo_link, /*repo_site_documents_link, repo_img_link,*/ repo_site_assets_link, content_language } from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = (props) => {

  const [lang, set_lang] = useState(localStorage.getItem('lang'));

  const location = useLocation().pathname;

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
      childs: []
    }
  }

  const [navbar, set_navbar] = useState(data_interface_navbar);
  const [/*refresh*/, setRefresh] = useState(0);

  const [isloading, setLoading] = useState(true);



  useEffect(() => {

    set_lang(localStorage.getItem('lang'))

    let getInfoNavbar = async () => {
      try {


        let res = await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)

        //  .then(res => {

        // res.data.forEach((v) => {
        for await (const v of res.data) {

          if (v.info.principal === true) {
            //set_dados(dados.current = v);

            if (lang !== 'pt') {
              let x = 0
              //content_language.forEach((cl) => {
              for await (const cl of content_language) {

                if (cl.code === lang) {

                  // v.content.childs.forEach((el) => {
                  for await (const el of v.content.childs) {
                    if (el.id_content !== '') {
                      try {
                        let res_2 = await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                        //  .then(res_2 => {
                        if (res_2.data.length > 0) {
                          if (res_2.data[0].info.language.length > 0) {
                            if (res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                              if (res_2.data[0].info.language[cl.id]['name'] !== '') {
                                el.title = res_2.data[0].info.language[cl.id]['name'];

                                x++
                                if (v.content.childs.length === x) {
                                  setRefresh(refresh => refresh + 1)
                                }
                              }
                            }
                          }
                          else {
                            x++
                            if (v.content.childs.length === x) {
                              setRefresh(refresh => refresh + 1)
                            }
                          }
                        }
                        else {
                          x++
                          if (v.content.childs.length === x) {
                            setRefresh(refresh => refresh + 1)
                          }
                        }
                      } catch (error) {
                        console.log('ERROR11', error)
                        throw new Error('ERROR11', error);
                      }
                    }
                    else {
                      if (el['language'] !== undefined && el['language'] !== null) {
                        if (el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') {
                          el.title = el.language[cl.id]['title']

                          x++
                          if (v.content.childs.length === x) {
                            setRefresh(refresh => refresh + 1)
                          }
                        }
                      }
                    }

                  }

                }
              }

            }

            set_navbar(v);
            setLoading(false)
          }
        }

        //})
      } catch (error) {
        console.log('ERRORS', error)
        setLoading(false)

      }
      //.catch(erro => console.log('ERRORS', erro))
    }

    //set_navbar(navbar = { id: '', info: '', content: { childs: [] } })

    getInfoNavbar()

    /*if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      change_lang('pt')
    }
    else {
      set_lang(localStorage.getItem('lang'))
    }*/

  }, [lang])


  const page_location = useLocation().pathname.split('/').pop();

  return isloading ?
    null
    :
    <footer id="footer" /*className={props.pag_welcome ? 'mt-0': ''}*/>
      {/*<img alt='Snow' className="img-fluid d-none" src={repo_img_link("banner_snow_footer.svg")} style={{ zIndex: 20, position: 'absolute', marginTop: `-1px`}}/>*/}
      <div className="container">
        {/*<div className="row d-none">
            <div className='col-12 text-center'>
              <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer" alt="Centro 2020" title="Centro 2020" >
                <img src={repo_site_assets_link("centro_2020_FEDER-branco.svg")} alt="Centro 2020" title="Centro 2020" className="img-fluid" />
              </a>
            </div>
          </div>*/}
        <div className="row py-5">
          <div className="col-12 text-white d-none">
            <h4 className="footer-heading">{Hosts.webSiteTitle}</h4>
          </div>
          <div className="col-12 mb-2 d-none">
            <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} >
              <img src={repo_logo_link("logo.svg")} width="180" height="75" alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="img-fluid" />
            </a>
          </div>

          <div className="col-12 mb-5 text-center">
            <h5 className="mt-2 mb-3">{language[lang].projeto_cofinanciado}</h5>
              {/*<a href="https://www.hotelalambique.com/templates/cadenas/air/images/hotels/SYN2006/COMPETE%202020.pdf"href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer" alt="Centro 2020" title="Centro 2020" >
                  <img src={repo_site_assets_link("centro_2020_FEDER-branco.svg")} alt="Centro 2020" title="Centro 2020" className="img-fluid" />
                </a>*/}
              <a href="https://www.hotelalambique.com/templates/cadenas/air/images/hotels/SYN2006/COMPETE%202020.pdf"/*href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')}*/ target="_blank" rel="noreferrer" >
                <img src={repo_site_assets_link("apoio1.svg")} alt="Compete 2020" title="Compete 2020" className="img-fluid" width={100} />

                <img src={repo_site_assets_link("apoio2.svg")} alt="Portugal 2020" title="Portugal 2020" className="img-fluid mx-3" width={100} />

                <img src={repo_site_assets_link("apoio3.svg")} alt="União Europeia - Fundos Europeus de Desenvolvimento Regional" title="União Europeia - Fundos Europeus de Desenvolvimento Regional" className="img-fluid" width={100} />
              </a>
          </div>

          <div className="col-6 col-md-4 col-lg-3">
            <h5 className="my-2">{language[lang].site_map}</h5>
            {
              navbar.content.childs.map((v, key) => {

                //console.log(v)
                let link = ''
                let current_top_check = ''
                v.id_content !== '' ?
                  link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                  :
                  link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                if (v.children !== undefined && v.children !== '' && v.children.length > 0) {

                  v.children[0].id_content !== '' ?
                    link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : Hosts.WEB_SITE_URI + 'pag/' + v.children[0].id_content)
                    :
                    link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : (v.children[0].link.substr(0, 4) !== 'http' && v.children[0].link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.children[0].link : (v.children[0].link.substr(0, 3) === 'www' ? 'http://' + v.children[0].link : v.children[0].link)))

                  current_top_check = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? v.children[0].slug : v.children[0].id_content);

                  if (current_top_check === '') {
                    if (location === '/' + v.children[0].link) {
                      current_top_check = v.children[0].link
                    }
                  }
                }

                return (
                  v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                    <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')}>
                      <h6 className="mb-2">
                        {v.title}
                      </h6>
                    </a>
                    :
                    <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')} target="_blank" rel="noreferrer">
                      <h6 className="mb-2">
                        {v.title}
                      </h6>
                    </a>
                )
              })
            }
            {
              /*navbar.content.childs.map((v, key) => {
   
                //console.log(v)
   
                if (v.id_content !== '') {
                  let link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content);
                  let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));
   
                  if(v.id_content === '98') {
                    if(v.children !== undefined && v.children !== '' && v.children.length > 0){
   
                      v.children.forEach((v2) => {
                        if(v2.id_content === '99'){
                          link = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                          current_top_check = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                        }
                      })
                    }
                  }
   
                  return (
                        <a key={key} href={link} className={(page_location === current_top_check ? 'current_bottom' : '')}>
                            <h6 className="mb-2">
                              {v.title}
                            </h6>
                        </a>
                    )
                }
                else {
                  let aux_title = v.title.toLowerCase()
                    return (
   
                        v.link.substr(0, 4) !== 'http' ?
                          <a key={key} href={Hosts.WEB_SITE_URI + v.link} className={(page_location === v.link ? 'current_bottom' : (page_location === aux_title ? 'current_bottom' : ''))}>
                              <h6 className="mb-2">
                                {v.title}
                              </h6>
                          </a>
                        :
                          <a key={key} href={v.link} className={(page_location === v.link ? 'current_bottom' : (page_location === aux_title ? 'current_bottom' : ''))} target="_blank" rel="noreferrer">
                              <h6 className="mb-2">
                                {v.title}
                              </h6>
                          </a>
                    )
                }
              })*/
            }

          </div>

          <div className="col-6 col-md-4 col-lg-3">
            <h5 className=" my-2">{language[lang].legal_information}</h5>
            <a className={(page_location === 'terms-conditions' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "terms-conditions"}>
              <h6 className="mb-2">{language[lang].terms_conditions}</h6>
            </a>
            <a className={(page_location === 'regulamento-interno' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "regulamento-interno"}>
              <h6 className="mb-2">{language[lang].rules_of_procedure}</h6>
            </a>
            {/*<a className={"text-white " + (page_location === 'devolucao-e-reembolso' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "devolucao-e-reembolso"}>
                <h6 className="mb-2">{language[lang].return_policy}</h6>
              </a>*/}
            <a href="https://www.livroreclamacoes.pt/" title={"Link - " + language[lang].complaint_book} target="_blank" rel="noreferrer">
              <h6 className="mb-2">{language[lang].complaint_book}</h6>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-2 text-center text-sm-left social-media">
            <h5 className="my-2">{language[lang].social_media}</h5>
            {
              Hosts.fbLink !== '' ?
                <a className="mr-3" href={Hosts.fbLink} title="Facebook" target="_blank" rel="noreferrer" >
                  <i className="bi bi-facebook"></i>
                  {/*<img src={repo_site_assets_link("facebook.svg")} width="25px" height="25px" alt="Facebook" className="img-fluid mr-2" />*/}
                </a>
              : null
            }
            {
              Hosts.twitterLink !== '' ?
                <a className="mr-3" href={Hosts.twitterLink} title="Twitter X" target="_blank" rel="noreferrer">
                  <i className="bi bi-twitter-x"></i>
                  {/*<img src={repo_site_assets_link("twitter.svg")} title="Twitter" alt="Twitter" className="img-fluid"/>*/}
                </a>
              : null
            }
            {
              Hosts.instaLink !== '' ?
                <a href={Hosts.instaLink} title="Instagram" target="_blank" rel="noreferrer" >
                  <i className="bi bi-instagram"></i>
                  {/*<img src={repo_site_assets_link("instagram.svg")} width="25px" height="25px" alt="Instagram" className="img-fluid" />*/}
                </a>
              : null
            }
          </div>
          <div className="col-12 col-md-12 col-lg-4 text-center text-lg-left">
            <h5 className="my-2">{language[lang].download_app}</h5>
            <a className='d-none' href="https://play.google.com/store/apps/details?id=" title="App Google Play" alt="App Google Play" target="_blank" rel="noreferrer" >
              <img src={repo_site_assets_link("googleplay.svg")} title="App Google Play" alt="App Google Play" className="img-fluid mb-2 mr-2" />
            </a>
            <a className='' href="https://apps.apple.com/us/app/golden-rock-alambique-spa/id6472725715" title="App Store" alt="App Store" target="_blank" rel="noreferrer" >
              <img src={repo_site_assets_link("applestore.svg")} title="App Store" alt="App Store"className="img-fluid mb-2" />
            </a>

            <h5 className="mt-4 mb-2">{language[lang].payment_methods}</h5>
            <img style={{marginTop: '-25px', maxWidth: '250px'}} src={repo_site_assets_link("cc-mbs-reta-white.png")} title="Easypay" alt="Easypay" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="col-12 text-center py-3 justify-content-center text-white">
        <ul className="list-inline mb-0 copyright">
          <li className="list-inline-item p-0">
            <span className="">
              <i className="bi bi-c-circle"></i>&nbsp;2024&nbsp;{Hosts.webSiteTitle}
            </span>
          </li>
          <li className="list-inline-item p-0">
            <span className="px-1">&#124;</span>
          </li>
          <li className="list-inline-item p-0">
            <span>{language[lang].developed}</span>
          </li>
          <li className="list-inline-item p-0">
            <span>
              <a href="http://sim.assec.pt" title="Website Assec Sim!" target="_blank" rel="noreferrer">
                {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                Assec Sim!
              </a>
            </span>
          </li>
        </ul>
      </div>
    </footer>


};
export default Footer;